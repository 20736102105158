import React from "react";

import AnimatedCursor from "react-animated-cursor";

const Cursor = () => {
  return (
    <AnimatedCursor
      innerSize={10}
      // innerSize={8}
      outerSize={8}
      color="255, 255, 255"
      // color="210, 110, 55"
      outerAlpha={0.4}
      innerScale={0.8}
      outerScale={4}
    />
  );
};

export default Cursor;
