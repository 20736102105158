import React from "react";
import Profile from "./Profile";
import ProfileImage from "./ProfileImage";
import Face from "../../assets/images/face.gif";
import Hand from "../../assets/images/hand.gif";
import Resume from "../../assets/file/resume.pdf";
import { profile } from "../../data";

const ProfileBar = () => {
  return (
    <div className="w-full lg:w-1/3 FlexAlign CardBg py-8">
      <div className="w-full">
        <ProfileImage src={Face} alt="face" />
        <Profile title="My Background" detail={<p>{profile.summary.en}</p>} />
        <Profile
          title="Professional Skills"
          detail={profile.tech.map((t, i) => (
            <button key={i} className="TagBtn">
              {t}
            </button>
          ))}
        />
        <Profile title="Main Interests" detail={<p>{profile.interests}</p>} />
        <ProfileImage className="mt-4 sm:mt-8" src={Hand} alt="hand" />
        <div className="w-full FlexJustify mt-4 sm:mt-8">
          <button className="Btn">
            <a href={Resume} target="_blank" rel="noopener noreferrer">
              Download Resume
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileBar;
