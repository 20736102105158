import React from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <nav className="h-16 sm:h-18 xl:h-20 w-full fixed top-0 left-0 FlexAlign justify-end backdrop-filter backdrop-blur z-50 px-6 sm:px-12">
      <ul className="FlexAlign">
        <li className="Hover mr-6">
          <Link to="/">Home</Link>
          {path === "/" && <hr />}
        </li>
        <li className="Hover">
          <Link to="/resume">Resume</Link>
          {path === "/resume" && <hr />}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
