import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FaButton = ({ className, href, icon, button }) => {
  return (
    <button className={`Btn ${className}`}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={icon} /> {button}
      </a>
    </button>
  );
};

export default FaButton;
