import React from "react";

const WorkImage = ({ id, title }) => {
  return (
    <div className="min-w-full">
      <img
        src={require(`../../assets/images/work-${id}ui.png`).default}
        alt={title}
        className="w-full object-contain"
        loading="lazy"
      />
    </div>
  );
};

export default WorkImage;
