import React from "react";

const Profile = ({ title, detail }) => {
  return (
    <div className="text-center">
      <h4 className="mt-4 sm:mt-8">{title}</h4>
      <div className="FlexJustify flex-wrap mt-2">{detail}</div>
    </div>
  );
};

export default Profile;
