import React from "react";

const Content = ({ content }) => {
  return (
    <div className="h-full w-auto sm:w-11/12 FlexJustify">
      <div className="h-full w-11/12 FlexCenter">{content}</div>
    </div>
  );
};

export default Content;
