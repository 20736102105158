import React from "react";
import Icon from "./Icon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faTwitterSquare,
  faGithubSquare,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {

  const currentTime = new Date();
  const year = currentTime.getFullYear()

  return (
    <div className="h-full w-full FlexCenter z-30 relative py-10">
      <div className="text-center">
        <h2>Reach Me</h2>
        <p className="Hover mt-2">
          <a href="tel://16047891650">+1 (604) 729 8919</a>
        </p>
        <p className="Hover mt-2">
          <a href="mailto:tanakayumi13@gmail.com">tanakayumi13@gmail.com</a>
        </p>
        <div className="FlexJustify mt-4">
          <Icon
            link="https://www.linkedin.com/in/ayumitanaka13"
            fa={<FontAwesomeIcon icon={faLinkedin} />}
          />
          <Icon
            link="https://github.com/ayumitanaka13"
            fa={<FontAwesomeIcon icon={faGithubSquare} />}
          />
          <Icon
            link="https://twitter.com/ayumitanaka_13"
            fa={<FontAwesomeIcon icon={faTwitterSquare} />}
          />
          <Icon
            link="https://medium.com/@ayumitanaka13"
            fa={<FontAwesomeIcon icon={faMedium} />}
          />
        </div>
        <div className="mt-2">
          <small>&copy; Ayumi Tanaka 2020-{year} / All Rights Reserved</small>
          <br />
          <small>
            This website is created with React, Tailwind CSS, and Firebase.
          </small>
        </div>
      </div>
    </div>
  );
};

export default Footer;
