import React, { useEffect } from "react";
import PageContainer from "../components/UI/PageContainer";
import ProfileBar from "../components/resume/ProfileBar";
import ExperienceBar from "../components/resume/ExperienceBar";
import { profile } from "../data";

const Resume = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer
      title="Ayumi Tanaka"
      subTitle={profile.title}
      main={
        <div className="flex flex-wrap lg:flex-nowrap">
          <ProfileBar />
          <ExperienceBar />
        </div>
      }
    />
  );
};

export default Resume;
