import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Loading from "./components/UI/Loading";
import Cursor from "./components/UI/Cursor";
import Background from "./components/UI/Background";
import Navbar from "./components/UI/Navbar";
import Home from "./pages/Home";
import Resume from "./pages/Resume";
import Work from "./pages/Work";
import Footer from "./components/UI/Footer";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <BrowserRouter>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="min-h-screen w-screen relative">
          <Cursor />
          <Background />
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/resume">
              <Resume />
            </Route>
            <Route path="/:title">
              <Work />
            </Route>
          </Switch>
          <Footer />
        </div>
      )}
    </BrowserRouter>
  );
};

export default App;
