import React from "react";

const MoreButton = ({ onClick, state }) => {
  return (
    <div className="w-full FlexJustify mb-8 sm:mb-16">
      <button
        type="button"
        onClick={onClick}
        className="Btn"
        aria-expanded={state ? "true" : "false"}
      >
        {`Show ${state ? "Less" : "More"}`}
      </button>
    </div>
  );
};

export default MoreButton;
