import React, { useEffect } from "react";
import Content from "./Content";
import Title from "./Title";

import { gsap, TimelineLite } from "gsap/all";
import CSSPlugin from "gsap/CSSPlugin";

gsap.registerPlugin(CSSPlugin);

const Page = ({ id, title, index, content }) => {
  const t1 = new TimelineLite({ delay: 0.4 });

  useEffect(() => {
    t1.from(
      `#${id}`,
      { duration: 2, y: 50, opacity: 0, ease: "ease-in", delay: 0.2 },
      "Start"
    );
    return () => {
      t1.pause();
    };
  }, []);

  return (
    <div className="h-screen w-full flex relative z-30" id={id}>
      <Title title={title} index={index} />
      <Content content={content} />
    </div>
  );
};

export default Page;
