import React, { useEffect } from "react";

import { TimelineLite } from "gsap/all";

const PageContainer = ({ id, title, subTitle, main }) => {
  const t1 = new TimelineLite({ delay: 0.4 });

  useEffect(() => {
    t1.from(
      "#top",
      { duration: 2, y: 50, opacity: 0, ease: "ease-in", delay: 0.2 },
      "Start"
    );
    return () => {
      t1.pause();
    };
  }, []);

  return (
    <div
      className="h-full w-full FlexJustify flex-wrap relative z-30 pt-32 pb-20"
      id="top"
    >
      <div className="w-5/6">
        <div className="text-center">
          <h1 className="text-5xl sm:text-7xl md:text-8xl lg:text-9xl xl:text-10xl 2xl:text-11xl">
            {title}
          </h1>
          <h4 className="mt-8 2xl:mt-12">{subTitle}</h4>
        </div>
        <div className="w-full mt-8">{main}</div>
      </div>
    </div>
  );
};

export default PageContainer;
