import React from "react";
import { Link } from "react-router-dom";

const OtherWorks = ({ otherWorks }) => {
  return (
    <div className="w-full mt-4">
      <div className="w-full flex relative overflow-x-scroll pb-2">
        <div className="flex flex-nowrap min-w-full">
          {otherWorks.map((otherWork, i) => (
            <div key={i} className="inline-block px-2">
              <Link
                to={`/${otherWork.title.toLowerCase().split(" ").join("-")}`}
              >
                <img
                  src={
                    require(`../../assets/images/work-${otherWork.id}a.png`)
                      .default
                  }
                  alt={otherWork.title}
                  className="max-w-2xs sm:max-w-xs overflow-hidden ImgHover"
                  loading="lazy"
                />
              </Link>
              <p className="mt-2">{otherWork.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtherWorks;
