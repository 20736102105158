import React from "react";
// import { works } from "../../data";
import { Link } from "react-router-dom";

const WorkCard = ({ id, title, summary, detail, tech }) => {
  return (
    <div className="h-full w-full FlexCenter">
      <div className="h-5/6 w-full flex CardBg">
        <Link
          to={`/${title.toLowerCase().split(" ").join("-")}`}
          className="FlexJustify"
        >
          <div className="h-full w-full sm:w-11/12 FlexCenter flex-wrap">
            <div className="h-auto sm:h-1/4 md:h-2/6 lg:h-1/6 w-full FlexAlign">
              <div className="text-center sm:text-left">
                <h2 className="block lg:hidden mb-1">{title}</h2>
                <h4>{summary}</h4>
                <div className="mt-2">
                  {tech.map((t, i) => (
                    <button key={i} className="TagBtn">
                      {t}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="h-1/4 sm:h-2/4 md:h-3/6 lg:h-4/6 w-full xl:w-5/6 FlexCenter">
              <img
                src={require(`../../assets/images/work-${id}a.png`).default}
                alt={title}
                className="h-full ImgHover"
                loading="lazy"
              />
            </div>
            <div className="h-auto sm:h-1/4 md:h-1/6 lg:h-1/6 w-full FlexAlign sm:justify-end">
              <p className="text-center sm:text-right">{detail}</p>
            </div>
          </div>
          {/* <div className="h-full w-1/12 hidden lg:flex items-center justify-center">
              <h3 style={{ writingMode: "vertical-lr" }}>{title}</h3>
          </div> */}
          <div className="h-full w-1/12 hidden lg:flex items-center justify-center">
            <div>
              <div className="text-center">
                <h3>0{id}</h3>
                <h3 className="my-4">|</h3>
              </div>
              <div className="FlexJustify">
                <h3 style={{ writingMode: "vertical-lr" }}>{title}</h3>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default WorkCard;
