import React from "react";
import FaButton from "../work/FaButton";
import { faLaptop } from "@fortawesome/free-solid-svg-icons";

const Experience = ({
  id,
  title,
  name,
  place,
  year,
  detail,
  link,
  product,
}) => {
  return (
    <div className="mt-4 sm:mt-8">
      <h5>{title}</h5>
      <h6 className={`${link && "Hover"} italic mt-2`}>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={link && "underline"}
        >
          {name}
        </a>
        {" - "}
        {place}
        {" / "}
        {year}
      </h6>
      <div className="mt-2">
        {detail.map((d, i) => (
          <p key={i}>{d}</p>
        ))}
      </div>

      {id && product.picture && (
        <div className="FlexAlign justify-between CardBg m-0 mt-2 p-2 sm:p-4">
          <div className="w-auto h-20">
            <a href={product.link} target="_blank" rel="noopener noreferrer">
              <img
                src={require(`../../assets/images/expe-${id}.png`).default}
                alt={title}
                className="h-full ImgHover shadow-none"
              />
            </a>
          </div>
          <div className="FlexAlign flex-wrap sm:flex-nowrap">
            <p className="Hover">
              <a href={product.link} target="_blank" rel="noopener noreferrer">
                {product.title}
              </a>
            </p>
            <FaButton
              className="hidden sm:block sm:ml-4"
              href={product.link}
              icon={faLaptop}
              button="Visit"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Experience;
