import React, { useState } from "react";
import Experience from "./Experience";
import MoreButton from "./MoreButton";

import { workExperiences, volExperiences, eduExperiences } from "../../data";

const ExperienceBar = () => {
  const [more, setMore] = useState(false);

  const ShowMore = () => {
    return workExperiences
      .slice(2, 5)
      .map((workExperience, i) => (
        <Experience
          key={i}
          id={workExperience.id}
          title={workExperience.title}
          name={workExperience.name}
          place={workExperience.place}
          year={workExperience.year}
          detail={workExperience.detail}
          link={workExperience.link}
          product={workExperience.product}
        />
      ));
  };

  return (
    <div className="w-full lg:w-2/3 FlexAlign CardBg py-8">
      <div className="w-full">
        <h4>Experience</h4>
        {workExperiences.slice(0, 2).map((workExperience, i) => (
          <Experience
            key={i}
            id={workExperience.id}
            title={workExperience.title}
            name={workExperience.name}
            place={workExperience.place}
            year={workExperience.year}
            detail={workExperience.detail}
            link={workExperience.link}
            product={workExperience.product}
          />
        ))}
        {more && <ShowMore />}
        <MoreButton onClick={() => setMore(!more)} state={more} />
        <h4 className="mt-8 sm:mt-12">Volunteer</h4>
        {volExperiences.map((volExperience, i) => (
          <Experience
            key={i}
            title={volExperience.title}
            name={volExperience.name}
            place={volExperience.place}
            year={volExperience.year}
            detail={volExperience.detail}
            link={volExperience.link}
          />
        ))}
        <h4 className="mt-8 sm:mt-12">Education</h4>
        {eduExperiences.map((eduExperience, i) => (
          <Experience
            key={i}
            title={eduExperience.title}
            name={eduExperience.name}
            place={eduExperience.place}
            year={eduExperience.year}
            detail={eduExperience.detail}
          />
        ))}
      </div>
    </div>
  );
};

export default ExperienceBar;
