import React from 'react'

const ProfileImage = ({className, src, alt}) => {
    return (
        <div className="w-full FlexJustify">
          <div className={`w-2/3 sm:w-1/3 lg:w-2/3 xl:w-1/2 ${className}`}>
            <img src={src} alt={alt} className="w-full object-contain" />
          </div>
        </div>
    )
}

export default ProfileImage
