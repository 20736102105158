import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const WorkSlides = ({ href, slidesMap }) => {
  const [isHover, setIsHover] = useState("flex");
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div
        className="w-full flex relative overflow-x-scroll"
        onMouseEnter={() => setIsHover("hidden")}
        onMouseLeave={() => setIsHover("flex")}
      >
        {slidesMap}
        <div
          className={`h-full ${isHover} items-center absolute top-0 right-0`}
        >
          <h4>
            <FontAwesomeIcon icon={faChevronRight} />
          </h4>
        </div>
      </div>
    </a>
  );
};

export default WorkSlides;
