import React, { useEffect, useState } from "react";
import Page from "../UI/Page";
import WorkCard from "./WorkCard";
import MoreButton from "./MoreButton";
import { works } from "../../data";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Works = () => {
  const [isHover, setIsHover] = useState("flex");
  const [more, setMore] = useState(false);
  const [scrollable, setScrollable] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollable]);

  const onScroll = () => {
    if (
      window.scrollY > window.innerHeight * 2 - window.innerHeight * 0.1 &&
      window.scrollY < window.innerHeight * 2 + window.innerHeight * 0.2
    ) {
      setScrollable(true);
    } else {
      setScrollable(false);
    }
  };

  const ShowMore = () => {
    return works
      .slice(5, 10)
      .map((work, i) => (
        <WorkCard
          key={i}
          id={work.id}
          title={work.title}
          summary={work.summary}
          detail={work.detail}
          tech={work.tech}
        />
      ));
  };

  return (
    <Page
      id="works"
      title="Works"
      index="3"
      content={
        <div
          className={`h-full w-full relative ${
            scrollable ? "overflow-y-auto" : "overflow-hidden"
          }`}
          onMouseEnter={() => setIsHover("hidden")}
          onMouseLeave={() => setIsHover("flex")}
        >
          {works.slice(0, 5).map((work, i) => (
            <WorkCard
              key={i}
              id={work.id}
              title={work.title}
              summary={work.summary}
              detail={work.detail}
              tech={work.tech}
            />
          ))}
          {more && <ShowMore />}
          {works.length > 5 && (
            <MoreButton onClick={() => setMore(!more)} state={more} />
          )}
          <div
            className={`w-full ${isHover} justify-center absolute bottom-10 sm:bottom-14 xl:bottom-16 2xl:bottom-20 left-0`}
          >
            <h4>
              <FontAwesomeIcon icon={faChevronDown} />
            </h4>
          </div>
        </div>
      }
    />
  );
};

export default Works;
