import React from "react";

const Title = ({ title, index }) => {
  return (
    <div className="h-full w-auto sm:w-1/12 FlexAlign pl-3 sm:pl-0">
      <div className="h-5/6 w-full">
        <div className="h-4/5 w-full FlexJustify py-8">
          <h3 style={{ writingMode: "vertical-lr" }}>
            {title}
          </h3>
        </div>
        <div className="h-1/5 w-full FlexCenter">
          <div className="text-center">
            <h3>|</h3>
            <h3 className="mt-4">0{index}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
