import React from 'react'

const WorkContent = ({className, contentTitle, content}) => {
    return (
        <div className={`mt-4 sm:mt-8 ${className}`}>
        <h5>{contentTitle}</h5>
        {content}
      </div>
    )
}

export default WorkContent
