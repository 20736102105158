import React from "react";
import Page from "../UI/Page";
import { profile } from "../../data";

const Top = () => {
  return (
    <Page
      id="top"
      title="Ayumi Tanaka"
      index="1"
      content={
        <div className="h-5/6 w-full FlexCenter">
          <div className="text-center">
            <h1 className="filter drop-shadow">Ayumi Tanaka</h1>
            <h4 className="mt-4 sm:mt-6">{profile.title}</h4>
          </div>
        </div>
      }
    />
  );
};

export default Top;
