import React from "react";

const Loading = () => {
  return (
    <div className="h-screen w-full FlexCenter">
      <div className="h-8 w-8 bg-white opacity-50 animate-ping rounded-full"></div>
    </div>
  );
};

export default Loading;
