import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import PageContainer from "../components/UI/PageContainer";
import WorkContent from "../components/work/WorkContent";
import WorkSlides from "../components/work/WorkSlides";
import WorkSlide from "../components/work/WorkSlide";
import WorkImage from "../components/work/WorkImage";
import OtherWorks from "../components/work/OtherWorks";
import FaButton from "../components/work/FaButton";
import { works } from "../data";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faLaptop,
  faLaptopCode,
} from "@fortawesome/free-solid-svg-icons";

const Work = () => {
  const { title } = useParams();

  const matchedItem = works.filter(
    (work) => work.title.toLowerCase() === title.split("-").join(" ")
  )[0];

  const otherWorks = works.filter((work) => work.id !== matchedItem.id);

  const slides = [
    `${matchedItem.id}a`,
    `${matchedItem.id}b`,
    `${matchedItem.id}c`,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [title]);

  return (
    <PageContainer
      title={matchedItem.title}
      subTitle={matchedItem.summary}
      main={
        <div className="FlexJustify">
          <div className="w-full lg:w-5/6 CardBg text-center py-8">
            <WorkSlides
              href={matchedItem.demo}
              slidesMap={slides.map((slide, i) => (
                <WorkSlide key={i} id={slide} title={matchedItem.title} />
              ))}
            />
            <WorkContent
              className="mt-8 sm:mt-12"
              contentTitle="Links"
              content={
                <div className="FlexJustify mt-2">
                  <FaButton
                    href={matchedItem.demo}
                    icon={faLaptop}
                    button="Visit"
                  />
                  {matchedItem.github && (
                    <FaButton
                      className="ml-4"
                      href={matchedItem.github}
                      icon={faLaptopCode}
                      button="Code"
                    />
                  )}
                </div>
              }
            />
            <WorkContent
              contentTitle="About"
              content={<p className="mt-2">{matchedItem.detail}</p>}
            />
            <WorkContent
              contentTitle="Technologies"
              content={
                <div className="mt-2">
                  {matchedItem.tech.map((t, i) => (
                    <button key={i} className="TagBtn">
                      {t}
                    </button>
                  ))}
                </div>
              }
            />
            {matchedItem.collaborator && (
              <WorkContent
                contentTitle="Collaborator"
                content={
                  <div className="mt-2">
                    {matchedItem.collaborator.map((c, i) => (
                      <button type="button" className="Btn mx-1" key={i}>
                        <a
                          href={c.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {c.name}
                        </a>
                      </button>
                    ))}
                  </div>
                }
              />
            )}
            {matchedItem.ui && (
              <WorkContent
                contentTitle="Design"
                content={
                  <WorkImage id={matchedItem.id} title={matchedItem.title} />
                }
              />
            )}
            <WorkContent
              className="mt-8 sm:mt-12"
              contentTitle="See Other Works"
              content={<OtherWorks otherWorks={otherWorks} />}
            />
            <div className="mt-8 sm:mt-12">
              <button className="Btn">
                <Link to="/#works">
                  <FontAwesomeIcon icon={faAngleDoubleLeft} /> Back to All Works
                </Link>
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Work;
