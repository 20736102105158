import React from "react";

const MoreButton = ({ onClick, state }) => {
  return (
    <div className="w-full mt-2 sm:mt-4">
      <button type="button" onClick={onClick} className="Btn" aria-expanded={state ? "true" : "false"}>
        {`Show ${state ? "Less" : "More"}`}
      </button>
    </div>
  );
};

export default MoreButton;
