import React from "react";

const Icon = ({ link, fa }) => {
  return (
    <div className="Hover text-4xl mx-2">
      <a href={link} target="_blank" rel="noopener noreferrer">
        {fa}
      </a>
    </div>
  );
};

export default Icon;
