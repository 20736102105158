import React from "react";

const WorkSlide = ({ id, title }) => {
  return (
    <div className="min-w-full px-1 sm:px-2">
      <img
        src={require(`../../assets/images/work-${id}.png`).default}
        alt={title}
        className="w-full ImgHover"
        loading="lazy"
      />
    </div>
  );
};

export default WorkSlide;
