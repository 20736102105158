import React, { useState } from "react";
import { Link } from "react-router-dom";
import Page from "../UI/Page";
import { profile } from "../../data";
import Face from "../../assets/images/face.gif";

const About = () => {
  const [english, setEnglish] = useState(true);

  return (
    <Page
      id="about"
      title="About"
      index="2"
      content={
        <div className="h-5/6 w-full flex flex-wrap lg:flex-nowrap">
          <div className="h-1/3 sm:h-1/2 lg:h-full w-full lg:w-2/5 FlexCenter">
            <div className="w-2/5 sm:w-1/3 lg:w-2/3">
              <img src={Face} alt="face" className="w-full object-contain" />
            </div>
          </div>
          <div className="h-2/3 sm:h-1/2 lg:h-full w-full lg:w-3/5 FlexCenter">
            <div className="w-full sm:w-5/6 text-center">
              <h2>Hi, I'm Ayumi</h2>
              <h4 className="mt-2 sm:mt-4">{profile.title}</h4>
              <p className="mt-2 sm:mt-4">
                {english ? profile.summary.en : profile.summary.ja}
              </p>
              <button className="Btn mt-4 sm:mt-6">
                <Link to="/resume">Resume</Link>
              </button>
              {/* <button
                className="Btn mt-4 sm:mt-6"
                onClick={() => setEnglish(!english)}
              >
                {english ? "日本語" : "English"}
              </button> */}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default About;
