export const works = [
  {
    id: 1,
    title: "Japanese City Pop",
    summary: "Music Social Media Web App",
    detail:
      "This is a web app that recommends you to listen to Japanese city pop music! Users can leave comments on their favorite artist's page and save their favorite albums after sign up.",
    tech: [
      "Python Flask",
      "Jinja",
      "Tailwind CSS",
      "SQL Alchemy",
      "PostgreSQL",
      "Firebase",
      "Heroku",
      "Figma",
    ],
    github: "https://github.com/ayumitanaka13/japanese-citypop",
    demo: "https://japanese-city-pop.herokuapp.com",
    collaborator: null,
    ui: true,
  },
  {
    id: 2,
    title: "Journaly",
    summary: "Diary Social Media Web App",
    detail:
      "This is a web app that stands for journal + journey. Users can post journals about their journey after sign up. Also, users can browse other people's journals and save favorite journals.",
    tech: [
      "Python Flask",
      "Jinja",
      "Tailwind CSS",
      "SQL Alchemy",
      "SQ Lite",
      "Heroku",
      "Figma",
    ],
    github: "https://github.com/ayumitanaka13/journaly",
    demo: "https://my-journaly.herokuapp.com",
    collaborator: null,
    ui: false,
  },
  {
    id: 3,
    title: "Share to Code",
    summary: "Developers Social Media Web App",
    detail:
      "This is a web app that helps beginners to learn to code. Users can post roadmaps on how to learn with text and YouTube videos after sign up. Also, users can search roadmaps with a keyword.",
    tech: [
      "Node.js",
      "Express",
      "React",
      "React Hooks",
      "Redux",
      "Tailwind CSS",
      "MongoDB",
      "Heroku",
    ],
    github: "https://github.com/ayumitanaka13/share-to-code",
    demo: "https://share-to-code.netlify.app",
    collaborator: null,
    ui: false,
  },
  {
    id: 4,
    title: "Recipefy",
    summary: "Recipe Sharing Web App",
    detail:
      "This is a web app that gives you great recipes. Users can search recipes with ingredients or recipe names, and share their recipes with the world after sign up.",
    tech: [
      "Java",
      "JavaEE",
      "Servlet",
      "JSP",
      "Tailwind CSS",
      "MySQL",
      "Heroku",
      "Figma",
    ],
    github: "https://github.com/DaiSugi01/recipefy",
    demo: "https://recipe-fy.herokuapp.com",
    collaborator: [
      {
        name: "Daiki Sugihara",
        link: "https://www.daikisugihara.com",
      },
    ],
    ui: true,
  },
  {
    id: 5,
    title: "Project iRISE",
    summary: "Non-profit Organization Website",
    detail:
      "This is a website for Project iRISE which is a non-profit organization that helps empower, educate and build community amongst survivors of human trafficking.",
    tech: ["Wix"],
    github: null,
    demo: "https://www.projectirise.org",
    collaborator: [
      {
        name: "Rhonelle Bruder",
        link: "https://www.rhonellebruder.com",
      },
    ],
    ui: false,
  },
  {
    id: 6,
    title: "Premiere",
    summary: "Interior Shop EC Website",
    detail:
      "This is a mockup EC website for the select interior shop. Users can browse items whose data come from our original API using Python Django and Postgresql.",
    tech: [
      "HTML",
      "Sass",
      "jQuery",
      "JavaScript",
      "Python Django",
      "PostgreSQL",
      "Heroku",
      "Figma",
    ],
    github: "https://github.com/ayumitanaka13/premiere",
    demo: "https://premiere-st.netlify.app",
    collaborator: [
      {
        name: "Miyu Setoyama",
        link: "https://miyusetoyama.com",
      },
    ],
    ui: false,
  },
  {
    id: 7,
    title: "Calc Log",
    summary: "Calculation Web App",
    detail:
      "This is a web app that allows users to save their calculation logs with a title and comments. They'll be stored in the cloud and can be checked anytime on any device.",
    tech: [
      "React",
      "React Hooks",
      "Redux",
      "Redux Thunk",
      "Tailwind CSS",
      "Firebase",
      "Figma",
    ],
    github: "https://github.com/danilo-mello/calculator",
    demo: "https://calc-log.vercel.app",
    collaborator: [
      {
        name: "Danilo Mello",
        link: "https://danilomello.io",
      },
    ],
    ui: false,
  },
  {
    id: 8,
    title: "We COOP",
    summary: "Time Management Web App",
    detail:
      "This is a web app that makes users' work time management more effortless. Users can calculate work hours easily with a pie chart depending on their situation.",
    tech: [
      "React",
      "React Hooks",
      "React Context",
      "Python Django",
      "PostgreSQL",
      "Tailwind CSS",
      "Firebase",
      "Figma",
    ],
    github: "https://github.com/We-Coop-Project",
    demo: "https://we-coop.netlify.app",
    collaborator: [
      {
        name: "Ami Yamanouchi",
        link: "https://amiyamanouchi.github.io",
      },
      {
        name: "Hiroshi Egawa",
        link: "https://www.linkedin.com/in/hiroshiegawa",
      },
      {
        name: "Miyu Setoyama",
        link: "https://miyusetoyama.com",
      },
    ],
    ui: false,
  },
  // {
  //   id: 9,
  //   title: "DP Code",
  //   summary: "Educational Platform Website",
  //   detail:
  //     "This is a website that helps students to learn computer science. It provides a variety of lectures, online classes, and private tutoring services.",
  //   tech: ["Illustrator", "Photoshop", "Figma"],
  //   github: null,
  //   demo: "https://dpcode.academy",
  //   collaborator: null,
  //   ui: false,
  // },
];

export const profile = {
  title: "A Front-end Developer based in Vancouver",
  // summary:
  //   "A front-end developer who loves building creative websites and applications that make life easier. A hard worker who combines a passion for web development with expertise in web design. Have developed several websites and applications using HTML, CSS, Sass, JavaScript, React, TypeScript and Python.",
  summary: {
    en: "A front-end developer who loves building creative websites and applications that make life easier. A hard worker who combines a passion for web development with expertise in web design. Have developed several websites and applications using HTML, CSS, Sass, JavaScript, React, TypeScript and Python.",
    ja: "武蔵野美術大学卒業後、都内にてグラフィックデザイナーとして2年勤務。その後、カナダでウェブデベロップメントを学び、現在はバンクーバーでフロントエンドデベロッパーとして働いています。HTML、CSS、Sass、JavaScript、React、TypeScript、Pythonを使った、ウェブサイトやアプリケーションの開発経験があります。",
  },
  tech: [
    "HTML",
    "CSS",
    "Sass",
    "JavaScript",
    "React",
    "React Hooks",
    "Redux",
    "TypeScript",
    "Python Django",
    "Python Flask",
    "Photoshop",
    "Illustrator",
    "Figma",
  ],
  interests: "Front-end Development, Web Design, Graphic Design",
};

export const workExperiences = [
  {
    id: 1,
    title: "Software Developer",
    name: "OXD",
    place: "Vancouver, Canada",
    year: "Sep 2021 - Present",
    desc: "OXD is an end-to-end design and technology consulting firm.",
    tech: ["HTML", "CSS", "Sass", "JavaScript", "Vue", "Drupal"],
    detail: [
      "- Developed web applications using Sass, JavaScript, Vue, and Drupal for many different clients",
      "- Maintained UI as user-friendly applications, implemented responsive design UI using Sass",
      "- Collaborated and worked in a team environment using Git, Github, Gitlab, and Jira",
    ],
    link: "https://oxd.com",
    product: {
      picture: false,
      title: null,
      link: null,
    },
  },
  {
    id: 2,
    title: "Frontend Developer",
    name: "Relayfy Digital Services",
    place: "Vancouver, Canada",
    year: "May 2021 - Aug 2021",
    desc: "Relayfy Digital Services is an information technology service company that developed a web application that enables customers to order and pay for food from restaurants.",
    tech: ["TypeScript", "React", "React Hooks", "Redux", "Sass"],
    detail: [
      "- Developed a contactless food ordering and payment web application using TypeScript, React, React Hooks, and Redux",
      "- Maintained UI as a mobile-first application, implemented responsive design UI using Sass",
      "- Collaborated and worked in a team environment using Git, Bitbucket, and Jira",
    ],
    link: "https://relayfy.com/business",
    product: {
      picture: true,
      title: "Relayfy Ordering Web App",
      link: "https://anorder.ca/mitbit",
    },
  },
  {
    id: 3,
    title: "Frontend Developer / Designer",
    name: "Appers Lab",
    place: "Vancouver, Canada",
    year: "Oct 2020 - Aug 2021",
    desc: "Appers Lab is a software / digital service company that specialized in building websites, applications, and proposing digital marketing.",
    tech: ["HTML", "CSS", "Sass", "Photoshop", "Illustrator", "Figma"],
    detail: [
      "- Building visual imagery for websites / applications and ensuring that they are in line with branding for clients using Photoshop and Illustrator",
      "- Designed and implemented user-friendly websites writing semantic HTML, CSS, and Sass",
      "- Managed user flows, process flows, site maps, and wireframes using Figma",
    ],
    link: "https://appers-lab.ca",
    product: {
      picture: true,
      title: "Appers Lab Website",
      link: "https://appers-lab.ca",
    },
  },
  {
    id: 4,
    title: "Web Designer",
    name: "DPCode Inc.",
    place: "Vancouver, Canada",
    year: "May 2021 - May 2021",
    desc: "DPCode is an online learning / tutoring service company that helps students to learn computer science.",
    tech: ["Figma"],
    detail: [
      "- Designed user-friendly UI for a website as an educational platform using Figma",
      "- Collaborated and worked with project managers and front-end developers",
    ],
    link: "https://dpcode.academy",
    product: {
      picture: true,
      title: "DP Code Academy Website",
      link: "https://dpcode.academy",
    },
  },
  {
    id: 5,
    title: "Graphic Designer",
    name: "Itsuki Art Gallery",
    place: "Tokyo, Japan",
    year: "Apr 2018 - Mar 2020",
    desc: "Itsuki Art Gallery is a private art gallery / company that supports artists and exhibits Japanese paintings.",
    tech: ["Photoshop", "Illustrator", "XD"],
    detail: [
      "- Designed graphics and layout for DM, posters, and flyers as advertisements using Photoshop, Illustrator, and XD",
      "- Researched and presented new creative design ideas for the marketing",
      "- Managed accurate and high-quality output of print advertisements",
    ],
    link: "https://itsukiart.com",
    product: {
      picture: false,
      title: null,
      link: null,
    },
  },
  {
    id: 6,
    title: "Visual Merchandiser",
    name: "Sanyo Shokai Inc.",
    place: "Tokyo, Japan",
    year: "Apr 2016 - Mar 2018",
    desc: "",
    tech: [],
    detail: [
      "- Created appealing and eye-catching visual displays that attract customers",
      "- Contributed to the increase in the store's sales",
    ],
    link: "https://www.sanyo-shokai.co.jp",
    product: {
      picture: false,
      title: null,
      link: null,
    },
  },
];

export const volExperiences = [
  {
    id: 1,
    title: "Web Developer / Designer",
    name: "Project iRISE",
    place: "Toronto, Canada",
    year: "July 2020 - Present",
    desc: "Project iRISE is a non-profit organization that helps empower, educate and build community amongst survivors of human trafficking.",
    tech: ["Wix"],
    detail: [
      "- Designed UI and developed the website from scratch using Wix",
      "- Applied feedback actively to improve the website",
    ],
    link: "https://www.projectirise.org",
  },
];

export const eduExperiences = [
  {
    id: 1,
    title: "Cornerstone International Community College Of Canada",
    name: "Web And Mobile App Development Diploma",
    place: "Vancouver, Canada",
    year: "Sep 2020 - Aug 2021",
    detail: [
      "- Acquired front-end skills such as HTML, CSS, Sass, JavaScript, React, React Hooks, Redux, Next.js, and TypeScript",
      "- Acquired back-end skills such as Java, Node, Express, Python, MySQL, PostgreSQL, and MongoDB",
      "- Deployed websites and applications using GitHub, Netlify, Heroku, and Firebase",
      "- Collaborated and worked in a team environment using Git, GitHub, and Jira",
    ],
  },
  {
    id: 2,
    title: "Musashino Art University",
    name: "Bachelor of the Art and Design",
    place: "Tokyo, Japan",
    year: "Apr 2012 - Mar 2016",
    detail: [
      "- Created artworks especially focusing on traditional painting, and modern painting",
      "- Designed websites, products, and graphics using  Photoshop and Illustrator",
      "- Learned systematic art history",
    ],
  },
];
