import React from "react";
import Top from "../components/top/Top";
import About from "../components/about/About";
import Works from "../components/works/Works";

const Home = () => {
  return (
    <>
      <Top />
      <About />
      <Works />
    </>
  );
};

export default Home;
